(function ($) {
  $(function () {
    if (!$('.search-section').length) return;
    $('form.search-form').submit(function (e) {
      if (!$('input[id="search-term"]').val().trim().length) {
        alert('Please enter a non-blank search.');
        e.preventDefault();
      }
    });
  });
})(jQuery);
